import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import Mdx from "../components/mdx";
import Button from "../components/button";
import Section from "../content/section";
import Header from "../content/header";
export const pageQuery = graphql`
  query {
    contentfulThanks {
      headline
      content {
        childMdx {
          body
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Header isVisible mdxType="Header" />
    <Section fullbleed={true} color='yellow' mdxType="Section">
  <br />
  <br />
  <Mdx mdxType="Mdx">{props.data.contentfulThanks.content}</Mdx>
  <br />
  <br />
  <Button href='/' appearance='ghost' mdxType="Button">
    Back to home
  </Button>
    </Section>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      